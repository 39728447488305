import "bootstrap";
import "packs/src/site.scss";
import $ from "jquery";
window.$ = $;

// Components
import "components/site/common/accordion";
import "components/site/feedback/feedback";
import "components/site/validator/validator";

// Icons
import { config, dom, library } from "@fortawesome/fontawesome-svg-core";
config.mutateApproach = 'sync';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faHourglassHalf,
  faLaptopMedical,
  faLightbulb,
  faRuler
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faCheck,
  faChevronDown,
  faChevronUp,
  faHourglassHalf,
  faLaptopMedical,
  faLightbulb,
  faRuler
);
dom.watch();

// Analytics
document.addEventListener("DOMContentLoaded", function () {
  if (typeof ga === "function") {
    ga("send", "pageview", window.location.pathname + window.location.search);
  }
});
