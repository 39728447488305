import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", function () {

	/**
	 * feedback button: animate only once
	 */
	let feedbackButton = document.getElementById("feedbackButton");

	if (feedbackButton && !Cookies.get("fbExpanded")) {
		Cookies.set("fbExpanded", true);

		setTimeout(function () {
			feedbackButton.classList.add("expanded");
		}, 1000);

		setTimeout(function () {
			feedbackButton.classList.remove("expanded");
		}, 3000);
	}

	/**
	 * UserReport iframe, fit height
	 * NOTE: doesn't work, CORS.
	 */
	/*
	let frame = document.getElementById("userReport");
	frame.height = frame.contentWindow.document.body.scrollHeight;
	*/

});
