import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", function () {

	let validatorNote = document.getElementById("validatorNote");

	if (validatorNote && !Cookies.get("vnShown")) {
		validatorNote.classList.add("show");
		validatorNote.classList.remove("d-none");

		$(validatorNote).on("closed.bs.alert", function () {
			Cookies.set("vnShown", true);
		});

	}

});
