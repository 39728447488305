document.addEventListener("DOMContentLoaded", function () {

	if ($(".accordion").length > 0) {
		let cardIdToOpen = window.location.hash;

		if ($(cardIdToOpen).length > 0) {
			$(cardIdToOpen).addClass("show");
			$(cardIdToOpen).prev().find("a").removeClass("collapsed");
			$([document.documentElement, document.body]).animate({
        scrollTop: $(cardIdToOpen).prev().offset().top
			}, 500);
		}
	}

});
